/* About.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.about-section,
.owner-section,
.activities-section {
  margin: 40px 0;
  text-align: center;
}

h1, h2, h3 {
  color: #333;
  font-size: 3rem;

}
h1{
  font-size: 4rem;
}
h2{
  font-size: 3rem;
}
h3{
  font-size: 2rem;
}

p {
  line-height: 1.6;
  color: #555;
  text-align: left;
}

.about-image,
.owner-image,
.activity-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.owner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.owner-info {
  margin-top: 20px;
}

.activities-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.activity-image {
  width: 100%;
  max-width: 300px;
  margin: 10px;
}

@media (min-width: 768px) {
  .owner-container {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }

  .owner-info {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .activities-container {
    flex-direction: column;
    align-items: center;
  }

  .activity-image {
    width: 100%;
    max-width: 80%;
    margin: 10px 0;
  }
}
