.apply-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.apply-form-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px; /* Reduced max-width */
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.form-group {
  margin-bottom: 10px; /* Reduced margin */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: calc(100% - 20px);
  padding: 8px; /* Reduced padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Reduced font size */
}

.form-group input[type="file"] {
  padding: 5px;
}

textarea {
  resize: vertical;
  min-height: 80px; /* Reduced min-height */
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  background: #C17176;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background: #c55f65;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
