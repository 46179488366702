body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}
.headercontainer{
  width: 100vw;
}

.seeking-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.seeking-content {
  max-width: 80vw;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.seeking-title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.seeking-text {
  margin-bottom: 20px;
  line-height: 1.6;
}

.seeking-requirements {
  margin-bottom: 20px;
}

.seeking-requirements h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 10px;
}

.seeking-requirements ul {
  list-style-type: disc;
  padding-left: 20px;
}

.seeking-requirements ul li {
  margin-bottom: 10px;
}

.seeking-cost {
  margin-bottom: 20px;
  color: #666;
}

.seeking-next-steps {
  margin-bottom: 20px;
}

.seeking-salary-expectations {
  margin-bottom: 20px;
}

.seeking-salary-expectations h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 10px;
}

.seeking-salary-expectations p {
  line-height: 1.6;
}
/* Existing styles */

.apply-now-button {
  text-align: center;
  margin-top: 20px;
}

.apply-now-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #5C5F5E;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.apply-now-link:hover {
  background-color: #444745;
}

.imgContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

.imgItem {
  flex: 1 0 30%; /* Adjust width for larger screens */
  margin: 10px;
  text-align: center;
}

.imgItem img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .imgItem {
    flex-basis: 100%; /* Full width for smaller screens */
  }
}


