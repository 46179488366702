
.header{
  width: 100vw;
  display: flex;
  justify-content: center;
}

.container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




.logo img {
  width: 100%;
}

.headerTxt {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px; /* Adjust as needed */
}

.navbar {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  flex-wrap: wrap;
  position: relative; 
  padding-bottom: 20px;
}

.navbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  height: 1px; /* Adjust thickness of the line */
  background-color: black;
}

.navbar a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: black;
  font-size: 1rem;
  margin-right: 2rem;
  transition: color 0.3s ease;
}
.navbar a:hover {
  color: #767575; /* Change to a lighter color on hover */
}



.motherChildContainer {
  width: 80vw;
}
.motherChildContainer img {
  width: 100%;
}

.textContainer1 {
  font-family: "Roboto", sans-serif;
  font: 1.5rem;
  font-weight: 400;
  width: 80vw;
  line-height: 1.6rem;
}
.textContainer2 {
  font-family: "Roboto", sans-serif;
  font: 1.7rem;
  text-align: center;
  margin-top: 3vw;
  font-weight: 700;
  color: rgb(120, 120, 120);
  width: 80vw;
  line-height: 1.6rem;
}


.locations-list {
  list-style-type: none;
  width: 70vw; /* Remove default list styles */
  padding: 0;
  display: flex;
  flex-wrap: wrap; /* Remove default padding */
  justify-content: center;
  align-items: center;
  text-align: center;
}

.locations-list li {
  position: relative;
  padding-bottom: 5px; /* Space between text and line */
  padding-right: 5px;
  padding-left: 5px;
}

.locations-list li:not(:last-child) {
  border-right: 1px solid #ccc; /* Line style and color */
  margin-bottom: 5px; /* Space between lines */
}

.line {
  width: 90%;
  height: 1px;
  background-color: black;
}

.familyContainer {
  width: 90vw;
  margin-top: 5vw;
  display: flex;
  justify-content: space-around;
}
.family1{
  margin-right: 2vw;
}
.familyContainer img {
  width: 40vw;
}
@media screen and (max-width: 768px) {
  .familyContainer img {
    width: 100%;
  }
  
}
 
.familyContainer a div {

  background-color: #a7a8a7;
  color: white;
}


.contactUsbutton{
  text-align: center;
  margin-top: 20px;
}



.contactUsbutton a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #5C5F5E;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.family1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.family2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.familyContainer a {
  text-decoration: none;
}



.buttonGroup{
  margin-bottom: 5vw;
}
.Footer{
  margin-top: 5vw;
}