body {
  margin-left: 5vw;
  margin-right: 5vw;
}



.logo {
  width: 100vw;
}
@media screen and (max-width: 768px) {
  .logo{
    width: 90vw;
  }
}

.logo img {
  width: 100%;
}

.headerTxt {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px; /* Adjust as needed */
}

.navbar {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  flex-wrap: wrap;
  position: relative; 
  padding-bottom: 20px;
}

.navbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; /* Adjust thickness of the line */
  background-color: black;
}

.navbar a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: black;
  font-size: 1rem;
  margin-right: 2rem;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #767575; /* Change to a lighter color on hover */
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .navbar.open {
    display: flex;
  }

  .navbar ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 100%;
    padding: 0;
  }

  .navbar ul li {
    width: 100%;
    padding: 10px 0;
  }

  .hamburger {
    display: flex;
  }

  .hamburger .line.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger .line.open:nth-child(2) {
    opacity: 0;
  }

  .hamburger .line.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  .navbarCom{
    margin-bottom: 5vw;
  }
}


@media screen and (min-width: 768px) {
.navbar ul{
  display: flex;
  justify-content: space-around;
  list-style-type: none;
}

.navbar ul li{
  margin-right: 5rem;

}

.navbar ul li a{
  font-size: 1.4rem;
}


  
}