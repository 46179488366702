/* Contact.css */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 0px;
}

.contact-us {
  max-width: 600px;
  width: 80vw;
  margin: 50px auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.contact-us:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.contact-us h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
}

.contact-us form {
  display: flex;
  flex-direction: column;
}

.contact-us .form-group {
  margin-bottom: 15px;
}

.contact-us label {
  display: block;
  margin-bottom: 5px;
  color: #555555;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.contact-us input,
.contact-us textarea {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-us input:focus,
.contact-us textarea:focus {
  border-color: #999999;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.contact-us textarea {
  resize: vertical; /* Allow vertical resizing of textarea */
}

.contact-us button {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background: #C17176;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  transition: background 0.3s ease, transform 0.3s ease;
}

.contact-us button:hover {
  background: #0056b3;
  transform: scale(1.02);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-us {
    padding: 20px;
  }

  .contact-us h2 {
    font-size: 22px;
  }

  .contact-us input,
  .contact-us textarea {
    font-size: 14px;
    padding: 10px;
  }

  .contact-us button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .contact-us {
    padding: 15px;
  }

  .contact-us h2 {
    font-size: 20px;
  }

  .contact-us input,
  .contact-us textarea {
    font-size: 12px;
    padding: 8px;
  }

  .contact-us button {
    font-size: 14px;
  }
}
