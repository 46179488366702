.footer {
    background-color: #c17176;
    width: 100%;
    padding: 2rem 0;
    text-align: center;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.footer h1, .footer h3 {
    color: white;
    margin: 0.5rem 0;
    font-family: "Montserrat", sans-serif;
}

.footer h1 {
    font-weight: 600;
    font-size: 2rem;
}


.footer h3 {
    font-weight: 300;
    font-size: 1rem;
}

.social-icons {
    margin-top: 1rem;
}
@media screen and (min-width: 1200px) {
  .footer h1 {
    font-size: 3rem;
  }
  .footer h3 {
    font-size: 1.5rem;
  }
  .social-icons {
    margin-top: 2rem;
  } 
  
}

.social-icon {
    color: white;
    font-size: 1.5rem;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #ffdd57; /* or any color you prefer for the hover effect */
}
