body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.process-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.process-content {
  max-width: 80vw;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.process-title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.process-steps {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666;
}

.process-steps h3 {
  margin-bottom: 20px;
}

.process-steps strong {
  color: #000;
}

.process-steps ul {
  list-style-type: disc;
  padding-left: 20px;
}

.process-steps ul li {
  margin-bottom: 10px;
}

.process-steps p {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
.process-steps h3{
  font-size: 1rem;
}
  
}

.imgContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    
    
}
.imgContainer div{
width: 25vw;
margin-right: 5px;
}
.imgContainer img{
    width: 100%;
}

.contactUsbutton{
  text-align: center;
  margin-top: 20px;
}



.contactUsbutton a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #5C5F5E;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.process-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.process-content {

  padding: 20px;
  width: 100%;
}

.process-title {
  font-size: 2em;
  text-align: center;
  margin: 20px 0;
}

.process-steps p {
  margin: 10px 0;
  font-size: 1.2em;
}

.contactUsbutton {
  text-align: center;
  margin: 20px 0;
}

.contactUsbutton a {
  text-decoration: none;
  color: white;
  background-color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
}

.contactUsbutton a:hover {
  background-color: #0056b3;
}

.imgContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.imgContainer div {
  flex: 1;
  max-width: 30%;
}

.imgContainer img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .process-steps p {
    font-size: 1em;
  }

  .imgContainer div {
    max-width: 45%;
  }

  .contactUsbutton a {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .process-title {
    font-size: 1.5em;
  }

  .imgContainer div {
    max-width: 100%;
  }

  .contactUsbutton a {
    padding: 6px 12px;
  }
}
